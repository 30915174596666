import React from 'react';
import { Link, navigate } from 'gatsby';
import styled from 'styled-components';

import Button from '@components/button/button.component';
import Layout from '@components/layout/layout.component';
import PageContainer from '@components/page-container/page-container.styles';
import Card from '@components/card/card.component';

const AButton = styled(Button)`
  margin: 0 auto;

  a {
    text-transform: uppercase;
    color: white;
    font-weight: bold;
  }
`;

const CardStyled = styled(Card)`
  margin: 10vh auto;
  text-align: center;
  height: 100%;

  h3 {
    margin-bottom: 5vh;
  }
`;

const NotFoundPage = ({ location }) => {
  const normalizedPathname = decodeURIComponent(location.pathname);

  if (normalizedPathname === '/sobre-nós') {
    navigate('/sobre-nos', { replace: true });
    return null;
  }

  return (
    <Layout location={location} title='Página não encontrada'>
      <PageContainer>
        <CardStyled>
          <h3>404: Página não encontrada</h3>
          <AButton primary>
            <Link to='/'>Voltar à página inicial</Link>
          </AButton>
        </CardStyled>
      </PageContainer>
    </Layout>
  );
};

export default NotFoundPage;
