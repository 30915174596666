import styled from 'styled-components';
import Theme from '../../theme';

export default styled.button`
  border-radius: ${Theme.buttonBorderRadius};
  box-shadow: ${Theme.buttonBoxShadow};
  padding: 7px 13px 7px 13px;
  font-size: 12px;
  min-height: 34px;
  min-width: 115px;
  cursor: pointer;
  
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  background-color: ${Theme.lightColor};
  border: solid 1px ${Theme.primaryColor};
  
  .icon {
    margin-right: 5px;  
  }

  &.primary-button {
    background-color: ${Theme.primaryColor};
    color: ${Theme.lightColor};
    font-weight: normal;
    font-style: normal;
    line-height: 14px;
    letter-spacing: 0.5px;
  }
  :not(.primary-button) {
    color: ${Theme.primaryColor}
  }
`;
