import React from 'react';

import Button from './button.styles';

const ButtonComponent = ({ children, primary = false, icon, ...props }) => {
  let className = primary ? 'primary-button' : '';

  if (props.className) {
    className += ` ${props.className}`
    delete props.className;
  }

  return (
    <Button className={className} {...props}>
      {icon}{children}
    </Button>
  );
};

export default ButtonComponent;
